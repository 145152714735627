import { AxiosResponse } from "axios";
import { Context } from "configureStore";
import makeDebug from "debug";
import Sentry from "lib/sentry";
import {
  all,
  call,
  fork,
  getContext,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { ApplicationState } from "reduxStore";
import {
  submitSurveyError,
  submitSurveyRequest,
  submitSurveySuccess,
} from "./actions";
import { SurveyAnswersActionTypes } from "./types";

const debug = makeDebug("store:surveyAnswers:saga");

const getSurveyAnswers = (state: ApplicationState) => ({
  values: state.surveyAnswers.values,
  comments: state.surveyAnswers.comments,
  segmentSelection: state.surveyAnswers.segmentSelection,
  followUpInformation: state.surveyAnswers.followUpInformation,
  skipReasons: state.surveyAnswers.skipReasons,
  visibleId: state.surveyAnswers.visibleId,
});

const getToken = (state: ApplicationState) => state.survey.token;
const getTokenType = (state: ApplicationState) => state.survey.tokenType;
const checkSubmitted = (state: ApplicationState) =>
  state.surveyAnswers.hasSubmitted;

// ### loading
function* handleSubmitSurvey(action: ReturnType<typeof submitSurveyRequest>) {
  debug("handle submit survey", action);
  const api: Context["api"] = yield getContext("api");
  const data: ReturnType<typeof getSurveyAnswers> = yield select(
    getSurveyAnswers
  );
  const token: string | null = yield select(getToken);
  const tokenType: string | null = yield select(getTokenType);
  const hasAlreadySubmitted: boolean = yield select(checkSubmitted);
  if (hasAlreadySubmitted) {
    yield put(submitSurveySuccess());
    return;
  }
  if (!token || !tokenType) {
    throw new Error("no token or token type");
  }
  const noValues = !data.values || Object.keys(data.values).length === 0;
  const noComments = !data.comments || Object.keys(data.comments).length === 0;
  if (noValues && noComments) {
    Sentry.captureMessage("submit survey without values and comments");
  }
  try {
    const response: AxiosResponse<unknown> = yield call(
      api.post,
      `/public/survey/answers`,
      {
        data,
        token,
        tokenType,
      }
    );
    debug("submitted survey response", response.data);
    yield put(submitSurveySuccess());
  } catch (err) {
    yield put(submitSurveyError(err as Error));
  }
}

function* watchSubmitSurveyRequest() {
  yield takeEvery(
    SurveyAnswersActionTypes.SUBMIT_SURVEY_REQUEST,
    handleSubmitSurvey
  );
}

function* surveyAnswersSaga() {
  yield all([fork(watchSubmitSurveyRequest)]);
}

export default surveyAnswersSaga;
